<template>
  <div>
    <el-form :model="params" size="mini" :inline="true">
      <el-form-item label="合同名称">
        <el-input v-model="params.queryName"></el-input>
      </el-form-item>
      <el-form-item label="签约状态">
        <el-select v-model="params.queryState">
          <el-option v-for="item in stateData" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="params.queryUserName"></el-input>
      </el-form-item>
      <el-form-item label="身份证">
        <el-input v-model="params.queryIdCard"></el-input>
      </el-form-item>
      <el-form-item label="是否过期">
        <el-select v-model="params.expire">
          <el-option v-for="item in expireData" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务公司">
        <el-select v-model="params.companyId" filterable @click.native="companyIdChange" style="width:100%">
          <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList(true)">查询</el-button>
        <el-button type="primary" size="mini" icon="el-icon-setting" @click="restForm">重置</el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-plus" @click="add(1)">新增合同</el-button>
        <el-button type="warning" size="mini" v-if="userType ==='3'" icon="el-icon-plus" @click="add(2)">批量签约
        </el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-upload" @click="exportUser">导出合同用户
        </el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-download"
          @click="handleBatchDownload">批量下载合同</el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-chat-dot-round" @click="propelling">
          一键推送</el-button>
      </el-form-item>
    </el-form>
    <el-alert v-if="tis" :title="tis" type="success" show-icon :closable="false" />
    <el-table :data="tableData" stripe ref="table" style="width: 100%" :row-key="getRowKeys"
      @selection-change="handleSelectionChange" :cell-style="columnStyle">
      <el-table-column type="selection" width="50" v-if="userType==='3'" :reserve-selection="true"></el-table-column>
      <el-table-column prop="name" width="250" label="合同名称"></el-table-column>
      <el-table-column prop="tempTypeName" width="150" label="合同类别"></el-table-column>
      <el-table-column prop="companyName" width="150" label="业务公司"></el-table-column>
      <el-table-column prop="stateName" width="100" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state===3">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===1" type="success">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===2" type="warning">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===4" type="danger">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===5" type="warning">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===6" type="danger">{{scope.row.stateName}}</el-tag>
          <el-tag v-else-if="scope.row.state===7" type="warning">{{scope.row.stateName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="tempName" width="150" label="模版名称"></el-table-column>
      <el-table-column prop="realName" width="150" label="微信用户"></el-table-column>
      <el-table-column prop="nickName" width="150" label="微信昵称"></el-table-column>
      <el-table-column prop="idCard" width="180" label="身份证"></el-table-column>
      <el-table-column prop="url" width="120" label="合同链接">
        <template slot-scope="scope">
          <el-button type="text" @click="openUrl(scope.row)" size="mini">预览</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="signTime" width="180" label="签约日期"></el-table-column>
      <el-table-column prop="createTime" width="180" label="创建日期"></el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template slot-scope="scope">
          <!-- <el-button v-if="scope.row.state === 3" type="text" size="mini" @click="apply(scope.row,'')">云合同审核</el-button> -->
          <template v-if="userType ==='3'">
            <el-button v-if="scope.row.state === 3" type="text" size="mini" @click="apply(scope.row,1)">e签宝审核
            </el-button>
          </template>
          <template v-if="userType ==='3'">
            <el-button v-if="scope.row.state === 6" type="text" size="mini" @click="apply(scope.row,1)">e签宝重新审核
            </el-button>
          </template>
          <template v-if="userType ==='3'">
            <el-button type="text" size="mini" @click="del(scope.row)">删除</el-button>
          </template>
          <template v-if="userType ==='3'">
            <el-button v-if="scope.row.expire === 2" type="danger" size="mini">已过期</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-pagination class="page-footer" :key="compoentsKey" background layout="total,prev,pager,next,sizes,jumper"
        :total="total" :page-sizes="[10,20,30,40,50,100]" @current-change="handlePageChange"
        @size-change="handleSizeChange" />
    </el-row>
    <!-- 新增合同模板 -->
    <el-dialog :visible.sync="formVisible" title="新增合同" width="600px" :close-on-click-modal="false">
      <el-form :model="ruleForm" ref="ruleForm" size="mini">
        <el-form-item label="合同名称" prop="name" :rules="[{required:true, message: '请输入合同名称', trigger: 'bulr'}]">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="合同模版（上传的合同文件内容必须匹配合同模版）" prop="tempId"
          :rules="[{required:true, message: '请选择合同模版', trigger: 'change'}]">
          <el-select v-model="ruleForm.tempId" filterable @click.native="tempIdChange(1)" style="width:100%">
            <el-option v-for="item in tempList" :key="item.id" :value="item.id"
              :label="`${item.name}（${item.typeName}）`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联企业（支持模糊搜索）" prop="companyId"
          :rules="[{required:true, message: '请选择关联企业', trigger: 'change'}]">
          <el-select v-model="ruleForm.companyId" filterable @click.native="companyIdChange" style="width:100%">
            <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-divider content-position="left">支持批量上传合同，合同名称规则（身份证号.pdf），合同格式pdf</el-divider>
        <el-form-item label="上传合同" prop="param" :rules="[{required:true, message: '请上传合同', trigger: 'change'}]">
          <el-upload class="upload-demo" ref="uploadPdf" action="#" v-model="ruleForm.param" accept="application/pdf" multiple :auto-upload="false" :file-list="fileList" :on-change="onChangeFile" :before-remove="beforeRemove"
            :on-remove="onRemoveFile">
            <el-button slot="trigger" size="mini" type="primary">批量选取文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="formVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onFormSubmit('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量签约 -->
    <el-dialog :visible.sync="batchSignVis" title="批量签约" width="1200px" :close-on-click-modal="false">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form :model="ruleForm" ref="batchRuleForm" size="mini">
            <el-form-item label="合同名称" prop="name" :rules="[{required:true, message: '请输入合同名称', trigger: 'bulr'}]">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="关联企业（支持模糊搜索）" prop="companyId"
              :rules="[{required:true, message: '请选择关联企业', trigger: 'change'}]">
              <el-select v-model="ruleForm.companyId" filterable @click.native="companyIdChange" style="width:100%">
                <el-option v-for="item in comanyList" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同模版（上传的合同签约人员表单必须匹配合同模板）" prop="tempId"
              :rules="[{required:true, message: '请选择合同模版', trigger: 'change'}]">
              <el-select v-model="ruleForm.tempId" filterable @click.native="tempIdChange(2)" style="width:100%">
                <el-option v-for="item in tempList" :key="item.uniqueCode" :value="item.uniqueCode"
                  :label="`${item.name}`"></el-option>
              </el-select>
            </el-form-item>
            <el-divider content-position="left">合同名称规则（身份证号），合同格式excel(可批量)</el-divider>
            <el-form-item label="上传合同签约人员" prop="param" :rules="[{required:true, message: '请上传合同', trigger: 'change'}]">
              <el-upload class="upload-demo" accept="application/vnd.ms-excel" :auto-upload="false" v-model="ruleForm.param" multiple ref="uploadPdf" action="#" :on-change="onChangeFileSign" :before-remove="beforeRemove" :on-remove="onRemoveFile" :disabled="signDisabled" style="display: inline-block;">
                <el-button :disabled="signDisabled" slot="trigger" size="mini" type="primary">批量选取文件</el-button>
              </el-upload>
              <el-button size="mini" type="primary" :disabled="signDisabled" @click="downloadSign" style="margin-left: 15px;vertical-align: top;">下载签约人员模板</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="14">
          <div style="font-size: 14px;color: #333;font-weight: bold;padding: 10px 0;">邀签人员预览</div>
          <el-table :data="memberData" border style="width: 100%">
            <el-table-column fixed prop="name" label="姓名" width="150">
            </el-table-column>
            <el-table-column fixed prop="idCard" label="身份证">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="userDel(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="page-footer" background layout="total,prev,pager,next,sizes,jumper" :total="memberTotal" :page-sizes="[10,20,30,40,50,100]" @current-change="memberPageChange" @size-change="memberSizeChange" />
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="batchSignVis = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onFormSubmit('batchRuleForm')">确 定 签 约</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pdfVisible" title="预览合同" width="1000px" :close-on-click-modal="false">
      <el-card class="box-card">
        <div slot="header" class="clearfix flexs">
          <div class="pages">
            <el-button @click="changePdfPage(0)" type="primary" size="mini" :disabled="currentPage==1">上一页</el-button>
            {{currentPage}} / {{pageCount}}
            <el-button @click="changePdfPage(1)" type="primary" size="mini" :disabled="currentPage==pageCount">下一页
            </el-button>
          </div>
          <el-button size="mini" @click="pally" type="danger">审核</el-button>
        </div>
        <pdf :src="pdfUrl" :page="currentPage" class="pdf_file" @num-pages="pageCount=$event"
          @page-loaded="currentPage=$event">
        </pdf>
      </el-card>
    </el-dialog>


  </div>
</template>

<script>
  import {
    initContractList,
    contractAdd,
    contractApply,
    contractNewDel,
    contractMsg,
    queryByCompanyId,
    pactExcelImport,
    userBatchList,
    deleteUser,
    invitedUser,
    downTemplate
  } from '@/api/contract_api'
  import {
    uploadPdf
  } from '@/api/common_api'
  import {
    xlsx
  } from "@/utils/xlsx"
  import store from '@/store'
  import pdf from 'vue-pdf'
  import axios from 'axios'
  import JSZip from 'jszip'
  import FileSaver from 'file-saver'
  const getFile = url => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer'
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error.toString())
      })
    })
  }
  export default {
    name: "contract",
    components: {
      pdf
    },
    data() {
      return {
        tableData: [],
        total: 0,
        stateData: [{
            value: '',
            label: '全部'
          }, {
            value: 1,
            label: '已签'
          }, {
            value: 2,
            label: '待签'
          }, {
            value: 3,
            label: '待审核'
          }, {
            value: 4,
            label: '禁用'
          },
          {
            value: 5,
            label: '签署中'
          }, {
            value: 6,
            label: '签署失败'
          }, {
            value: 7,
            label: '未登记'
          }
        ],
        expireData: [{
          value: '',
          label: '全部'
        }, {
          value: 2,
          label: '已过期'
        }],
        params: {
          queryName: '',
          queryState: '',
          queryIdCard: '',
          queryUserName: '',
          expire: '',
          companyId: '',
          pageNum: 1,
          pageSize: 10,
          deleteState: 1
        },
        formVisible: false,
        ruleForm: {
          param: [],
          name: '',
          tempId: '',
          companyId: ''
        },
        batchSignVis: false,
        signDisabled: true,
        memberData: [],
        memberTotal: 0,
        memberParams: {
          pageNum: 1,
          pageSize: 10,
          batchCode: "",
        },
        fileList: [],
        tempList: [],
        comanyList: [],
        pdfVisible: false,
        pdfId: '',
        pdfUrl: '',
        currentPage: 1,
        pageCount: 0,
        numPages: 0,
        goTopShow: false,
        selectItem: [],
        tis: "",
        compoentsKey: 1,
        flag: ''
      };
    },
    computed: {
      setUserType() {
        return this.$store.getters.userId
      },
      userType() {
        return sessionStorage.getItem('userType')
      }
    },
    watch: {
      setUserType: {
        handler(newValue) {
          this.loadList(true);
        }
      }
    },
    mounted() {
      this.loadList(true);
    },
    methods: {
      columnStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (row.expire === 2) {
          if (column.label === '操作') {
            return 'background: #ebd4d4;'
          }
        }
      },
      // 邀约人员列表
      async getUserBatchList(flag) {
        this.memberData = [];
        if (flag) {
          this.memberParams.pageNum = 1;
        }
        const req = await userBatchList(this.memberParams)
        if (req.code == 200) {
          this.memberData = req.data.list;
          this.memberTotal = req.data.total;
        }
      },
      memberPageChange(page) {
        this.memberParams.pageNum = page;
        this.getUserBatchList(false);
      },
      memberSizeChange(page) {
        this.memberParams.pageSize = page;
        this.getUserBatchList(false);
      },
      // 下载签约人员模板
      async downloadSign() {
        const req = await downTemplate(this.ruleForm.tempId)
        const blob = new Blob([req]);
        const fileName = '批量签约导入模板.xlsx';
        const elink = document.createElement('a'); // 创建a标签
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click(); // 点击下载
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink); // 释放标签
      },
      async userDel(id) {
        let req = await deleteUser(id)
        if (req.code == 200) {
          this.$message.success('删除成功')
          this.getUserBatchList(true)
        }
      },
      async loadList(flag) {
        if (flag) {
          this.params.pageNum = 1;
          this.compoentsKey += 1;
        }
        const userType = sessionStorage.getItem('userType');
        if (userType === '1') {
          if (this.$store.getters.userId) {
            this.params.enterprises = [this.$store.getters.userId];
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            this.params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        } else if (userType === '2') {
          if (this.$store.getters.userId) {
            this.params.enterprises = [this.$store.getters.userId]
          } else {
            const canmanyList = await store.dispatch('user/getUserIdCanmanyList', sessionStorage.getItem('id'));
            this.params.enterprises = canmanyList.map(v => v.enterpriseId);
          }
        }
        let req = await initContractList(this.params);
        if (req.code === 200) {
          this.tableData = req.data.list;
          this.total = req.data.total;
        }
      },
      handlePageChange(page) {
        this.params.pageNum = page;
        this.loadList(false);
      },
      handleSizeChange(page) {
        this.params.pageSize = page;
        this.loadList(false);
      },
      openUrl(item) {
        if (!item.url) {
          return;
        }
        var form = document.createElement('form');
        if (item.state === 1) {
          form.action = item.downUrl;
        } else {
          form.action = item.url;
        }
        console.log(form);
        form.target = '_blank';
        form.method = 'get';
        document.body.appendChild(form);
        form.submit();
      },
      getRowKeys(row) {
        return row.id
      },
      handleSelectionChange(val) {
        let ids = [];
        val.forEach(item => {
          ids.push(item)
        });
        this.selectItem = ids;
        this.tis = "已选择 " + val.length + " 项合同";
        if (val.length == 0) {
          this.tis = "";
          this.selectItem = [];
        }
      },
      handleBatchDownload() {
        if (this.selectItem.length === 0) {
          return this.$message.error("请勾选要导出的合同！");
        }
        let _this = this;
        const data = this.selectItem;
        const zip = new JSZip()
        const cache = {}
        const promises = []
        data.forEach(item => {
          const promise = getFile(item.url).then(data => { // 下载文件, 并存成ArrayBuffer对象
            const file_name = item.name + "_" + item.realName + ".pdf" // 获取文件名
            zip.file(file_name, data, {
              binary: true
            }) // 逐个添加文件
            cache[file_name] = data
          })
          promises.push(promise)
        })
        Promise.all(promises).then(() => {
          zip.generateAsync({
            type: "blob"
          }).then(content => { // 生成二进制流
            FileSaver.saveAs(content, "合同打包下载.zip") // 利用file-saver保存文件
            _this.clearSelection();
          })
        })
      },
      clearSelection() {
        this.$refs.table.clearSelection();
        this.selectItem = [];
        this.tis = "";
      },
      restForm() {
        this.params.queryName = '';
        this.params.queryState = '';
        this.params.queryUserName = '';
        this.params.expire = '';
        this.params.queryIdCard = '';
        this.loadList(true);
      },
      //新增
      /*async batcCodeChange(){
           this.batcCodeList = await store.dispatch('user/getBatchCodeList');
      },*/
      async tempIdChange(type) {
        this.tempList = [];
        if (type == 1) {
          // 新增合同
          const _tempList = await store.dispatch('user/getTemplateList', {});
          this.tempList = _tempList.filter(v => {
            return v.status === 1 && v.placeholderStatus === 1
          });
        } else if (type == 2) {
          // 批量签约
          if (this.ruleForm.companyId) {
            const _tempList = await queryByCompanyId(this.ruleForm.companyId)
            this.tempList = _tempList.data;
            this.signDisabled = false;
          } else {
            this.$message.error(`请优先选择关联企业`)
          }
        }
      },
      async companyIdChange() {
        this.comanyList = [];
        this.comanyList = await store.dispatch('user/getComanyList');
      },
      async add(type) {
        this.clearForm();
        if (type == 1) {
          // 新增合同
          this.formVisible = true;
          this.$nextTick(() => {
            this.$refs.ruleForm.resetFields();
          })
        } else if (type == 2) {
          // 批量签约
          this.batchSignVis = true;
          this.memberParams.batchCode = "";
          this.getUserBatchList(true);
          this.$nextTick(() => {
            this.$refs.batchRuleForm.resetFields();
          })
        }
      },
      clearForm() {
        this.ruleForm = {
          param: [],
          name: '',
          tempId: '',
          companyId: ''
        };
        this.signDisabled = true;
        this.memberParams.batchCode = "";
        this.fileList = [];
      },
      async onChangeFile(file, fileList) {
        let hash = {};
        this.fileList = fileList.reduceRight((item, next) => {
          hash[next.name] ? '' : hash[next.name] = true && item.push(next);
          return item
        }, []);
        const _flag = this.ruleForm.param.findIndex(item => item.lastModified === file.raw.lastModified) !== -1;
        if (!_flag) {
          let formData = new FormData();
          formData.append('file', file.raw);
          let req = await uploadPdf(formData);
          if (req.code === 200) {
            this.ruleForm.param.push({
              name: file.raw.name,
              url: req.data,
              uid: file.raw.uid,
              lastModified: file.raw.lastModified
            })
          }
        }
      },
      async onChangeFileSign(file, fileList) {
        let hash = {};
        this.fileList = fileList.reduceRight((item, next) => {
          hash[next.name] ? '' : hash[next.name] = true && item.push(next);
          return item
        }, []);
        const _flag = this.ruleForm.param.findIndex(item => item.lastModified === file.raw.lastModified) !== -1;
        if (!_flag) {
          let formData = new FormData();
          formData.append('pactName', this.ruleForm.name);
          formData.append('templateUnionCode', this.ruleForm.tempId);
          formData.append('companyId', this.ruleForm.companyId);
          formData.append('file', file.raw);
          let req = await pactExcelImport(formData);
          if (req.code === 200) {
            this.memberParams.batchCode = req.data;
            this.getUserBatchList(true);
            this.ruleForm.param.push({
              name: file.raw.name,
              uid: file.raw.uid,
              lastModified: file.raw.lastModified
            })
          }
        }
      },
      // 移除文件之前
      beforeRemove(file, fileList) {
        return this.$message.error(`${file.name}移除`)
      },
      onRemoveFile(file, fileList) {
        let paramList = this.ruleForm.param;
        this.ruleForm.param.splice(paramList.findIndex(item => item.uid === file.uid), 1);
      },
      onFormSubmit(formName) {
        console.log(formName);
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (formName == 'ruleForm') {
              let _data = this.ruleForm.param;
              let newArray = [];
              _data.forEach(el => {
                let params = {
                  name: el.name,
                  url: el.url
                }
                newArray.push(params);
              });
              let pop = {
                "data": newArray
              }
              let params = {
                param: JSON.stringify(pop),
                name: this.ruleForm.name,
                tempId: this.ruleForm.tempId,
                companyId: this.ruleForm.companyId
              }
              let req = await contractAdd(params);
              if (req.code === 200) {
                this.$message.success('新增合同成功');
                this.formVisible = false;
                this.loadList(true);
              }
            } else if (formName == 'batchRuleForm') {
              if (this.memberParams.batchCode) {
                let req = await invitedUser(this.memberParams.batchCode);
                if (req.code === 200) {
                  this.$message.success('邀请成功');
                  this.batchSignVis = false;
                  this.loadList(true);
                }
              }
            }
          } else {
            return false;
          }
        });
      },
      //导出
      exportUser() {
        if (this.selectItem.length === 0) {
          return this.$message.error("请勾选要导出的合同！");
        }
        let fields = {
          title0: "编号",
          title1: "合同名称",
          title2: "签约状态",
          title3: "微信用户",
          title4: "身份证号",
          title5: "创建时间",
          title6: "签约时间"
        };
        let arry = [];
        let i = 0;
        this.selectItem.forEach(ele => {
          i++;
          arry.push({
            title0: i,
            title1: ele.name,
            title2: ele.stateName,
            title3: ele.realName,
            title4: ele.idCard,
            title5: ele.createTime,
            title6: ele.signTime,
          });
        });
        xlsx(arry, fields, "合同签约列表");
        this.clearSelection();
      },

      apply(row, flag) {
        this.pdfId = row.id;
        this.flag = flag;
        this.pdfUrl = pdf.createLoadingTask(row.url);
        this.pdfVisible = true;
      },
      changePdfPage(val) {
        if (val === 0) {
          this.currentPage--
        } else {
          this.currentPage++
        }
      },
      pally() {
        this.$confirm('请仔细查看合同，审核过后不能修改？', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          let params = {
            id: this.pdfId,
            flag: this.flag
          }
          let req = await contractApply(params);
          if (req.code === 200) {
            this.$message.success("操作成功");
            this.pdfVisible = false;
            this.loadList(false);
          }
        }).catch((error) => {});
      },
      del(row) {
        this.$confirm('确定删除（' + row.name + '）', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          const params = {
            id: row.id,
            deleteState: 2
          }
          let req = await contractNewDel(params);
          if (req.code === 200) {
            this.$message.success("删除成功");
            this.loadList(false);
          }
        }).catch((error) => {});
      },
      propelling() {
        this.$confirm('每天只有推送一次，只推送合同过期未签约的用户！是否推送', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          let req = await contractMsg();
          if (req.code === 200) {
            this.$message.success("操作成功");
            this.loadList(false);
          }
        }).catch((error) => {});
      }
    }
  };
</script>
<style>
  .flexs {
    display: flex;
  }

  .pages {
    flex: 1;
  }
</style>
