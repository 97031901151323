<template>
    <div>
       <el-form :model="params" size="mini" :inline="true">
        <el-form-item label="企业名称">
            <el-input v-model="params.queryName"></el-input>
        </el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="loadList(true)">查询</el-button>
        <el-button type="danger" size="mini" v-if="userType ==='3'" icon="el-icon-plus" @click="add">新增企业</el-button>
       </el-form>
        <el-table :data="tableData" stripe>
            <el-table-column align="center" type="index" width="55" label="序号"></el-table-column>
            <el-table-column prop="name" label="企业名称"></el-table-column>
            <el-table-column prop="contactMoblie" label="联系人号码"></el-table-column>
            <el-table-column prop="creditCode" label="社会统一信用代码"></el-table-column>
            <el-table-column prop="stateName" label="状态"></el-table-column>
            <el-table-column prop="packCount" label="合同总数"></el-table-column>
            <el-table-column prop="sysName" label="创建人"></el-table-column>
            <el-table-column prop="address" label="企业地址"></el-table-column>
            <el-table-column prop="createTime" label="创建日期"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" v-if="userType ==='3'" @click="apply(scope.row)">{{scope.row.state === 1 ? '禁用':'启用'}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-pagination
            class="page-footer"
            background
            layout="total,prev,pager,next,sizes,jumper"
            :total="total"
            :page-sizes="[10,20,30,40,50]"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            />
        </el-row>

        <el-dialog :visible.sync="visible" :title="title" :width="width" :close-on-click-modal="false">
            <el-form :model="ruleForm" ref="ruleForm" size="mini">
                <el-form-item label="企业名称" prop="name" :rules="[{required:true, message: '请输入企业名称', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="联系人手机" prop="contactMoblie" :rules="[{required:true, message: '请输入联系人手机', trigger: 'bulr'},
                { required: true, pattern: /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,6,7,8]|8[0-9]|9[1,8,9])\d{8}$/, message: '手机格式错误', trigger: 'blur' }]">
                    <el-input maxlength="11" v-model="ruleForm.contactMoblie"></el-input>
                </el-form-item>
                 <el-form-item label="社会统一信用代码" prop="creditCode" :rules="[{required:true, message: '请输入社会统一信用代码', trigger: 'bulr'}]">
                    <el-input v-model="ruleForm.creditCode"></el-input>
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input type="textarea" v-model="ruleForm.address"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onFormSubmit('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { initConmanyList,addConmany,applyConmany} from '@/api/conmany_api'
import store from '@/store'
export default {
    name: "Comany",
    data() {
        return {
            tableData:[],
            total:0,
            params:{
                queryName:'',
                pageNum:1,
                pageSize:10
            },
            title:'新增企业',
            width:'500px',
            visible:false,
            ruleForm:{
                name:'',
                contactMoblie:'',
                creditCode:'',
                address:''
            },
        };
    },
    computed: {
        setUserType(){
            return this.$store.getters.userId
        },
        userType(){
            return sessionStorage.getItem('userType')
        }
    },
    watch: {
        setUserType: {
            handler(newValue){  
               this.loadList(true);
            }
        }
    },
    mounted(){
        this.loadList(true);
    },
    methods: {
        async loadList(flag){
            if(flag){
                this.params.pageNum = 1;
            }
            const userType = sessionStorage.getItem('userType');
            if(userType === '1'){
                if(this.$store.getters.userId){
                    this.params.enterprises = [this.$store.getters.userId];
                }else{
                    const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
                    this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
                }
            }else if(userType === '2'){
                if(this.$store.getters.userId){
                    this.params.enterprises = [this.$store.getters.userId]
                }else{
                    const canmanyList = await store.dispatch('user/getUserIdCanmanyList',sessionStorage.getItem('id'));
                    this.params.enterprises = canmanyList.map(v=>v.enterpriseId);
                }
            }
            let req = await initConmanyList(this.params);
            if(req.code === 200){
                this.tableData = req.data.list;
                this.total = req.data.total;
            }
        },
        handlePageChange(page) {
           this.params.pageNum = page;
            this.loadList(false);
        },
        handleSizeChange(page) {
            this.params.pageSize = page;
            this.loadList(false);
        },
        apply(row){
            let text = row.state === 1 ? '确定禁用！' : '确定启用！';
            let state = row.state === 1 ? 2 : 1;
            this.$confirm(text, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let params = {id:row.id,state:state}
                let req = await applyConmany(params);
                if(req.code === 200){
                this.$message.success("操作成功");
                this.loadList(false);
                }
            }).catch(() => {});
        },
        add(){
            this.visible = true;
            this.ruleForm = {
                name:'',
                contactMoblie:'',
                creditCode:'',
                address:''
            }
        },
        onFormSubmit(formName){
            this.$refs[formName].validate(async (valid) => {
            if (valid) {
               let req = await addConmany(this.ruleForm);
               if(req.code === 200){
                   this.$message.success('新增企业成功');
                   this.visible = false;
                   this.loadList(true);
               }
            } else {
                return false;
            }
            });
        }
    }
};
</script>

